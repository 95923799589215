.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-qualified {
    background-color: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background-color: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background-color: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background-color: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background-color: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background-color: #FFD8B2;
    color: #805B36;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #C63737;
}

.image-text {
  vertical-align: middle;
  margin-left: .5rem;
}

.p-multiselect-representative-option {
  display: inline-block;
  vertical-align: middle;

  img {
    vertical-align: middle;
    width: 24px;
  }

  span {
    margin-top: .125rem;
  }
}

.country-item {
  display: flex;
  align-items: center;

  img.flag {
    width: 18px;
    margin-right: .5rem;
  }
}
