.layout-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: margin-left 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-topbar {
  background-color: var(--surface-a);
  padding: 0;
  height: 70px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid var(--surface-d);
  display: flow-root;
  align-items: center;
  padding: 15px 10px;
  margin-left: 250px;

  .mobile-logo {
    display: none;
  }

  .topbar-left {
    float: left;
  }

  .topbar-right {
    float: right;
  }

  .menu-button {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    color: #495057;
    overflow: hidden;
    border-radius: 4px;
    background: #f8f9fa;
    transition: background-color 0.2s, box-shadow 0.2s;
    display: inline-block;
    cursor: pointer;
    outline: 0 none;

    &:hover {
      background-color: var(--surface-c);
    }

    i {
      font-size: 24px;
      line-height: inherit;
    }
  }

  .app-theme {
    background-color: var(--primary-color);
    color: var(--primary-color-text);
    padding: 0.5rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 39px;
    height: 39px;

    img {
      width: 25px;
    }
  }

  .topbar-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 100%;
    margin-left: auto;
    display: flex;

    > li {
      height: 70px;
      line-height: 70px;

      > a {
        text-decoration: none;
        color: var(--text-color);
        min-width: 120px;
        font-size: 16px;
        display: block;
        text-align: center;
        user-select: none;
        line-height: 68px;
        border-bottom: 2px solid transparent;
        transition: border-bottom-color 0.2s;
        cursor: pointer;

        &:hover,
        &:focus {
          border-bottom-color: var(--primary-color);
        }

        &:focus {
          z-index: 1;
          outline: 0 none;
          transition: box-shadow 0.2s;
          box-shadow: inset 0 0 0 0.2em $focusBorderColor;
        }
      }

      &.topbar-submenu {
        position: relative;

        > ul {
          position: absolute;
          transform-origin: top;
          top: 70px;
          right: 0;
          z-index: 1;
          width: 275px;
          max-height: 400px;
          background-color: var(--surface-f);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          overflow: auto;
          list-style-type: none;
          padding: 1rem;
          margin: 0;
          border-radius: 3px;

          > li {
            line-height: 1;

            &.topbar-submenu-header {
              display: block;
              color: var(--text-color-secondary);
              font-weight: 600;
              user-select: none;
              padding: 1.5rem 0 1rem 0;
              font-size: 0.857rem;
              text-transform: uppercase;

              &:first-child {
                padding-top: 1rem;
              }
            }
          }

          a {
            text-decoration: none;
            color: var(--text-color);
            padding: 0.5rem;
            display: flex;
            align-items: center;
            user-select: none;
            border-radius: 3px;
            cursor: pointer;

            &:hover {
              background-color: var(--surface-c);
            }

            span {
              margin-left: 0.5rem;
            }

            i {
              font-size: 18px;
              color: var(--text-color-secondary);
            }

            img {
              width: 32px;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }

    .theme-badge {
      padding: 2px 4px;
      vertical-align: middle;
      border-radius: 3px;
      color: #ffffff;
      font-weight: bold;
      font-size: 11px;
      position: relative;
      top: -1px;
    }

    .theme-badge.material {
      background: linear-gradient(to bottom, #22C36D, #22C36D);
    }

    .theme-badge.bootstrap {
      background: linear-gradient(to bottom, #563d7c, #966bd8);
    }

    .theme-badge.darkmode {
      background: linear-gradient(to bottom, #141d26, #5a6067);
    }
  }
}
