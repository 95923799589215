$sidebarWidth: 250px;

.layout-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #001E59 !important;
  width: $sidebarWidth;
  border-right: 1px solid var(--surface-d);
  user-select: none;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

  .logo {
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border-right: 1px solid var(--surface-d);
    border-bottom: 1px solid var(--surface-d);
    background-color: white;

    img {
      width: 180px;
    }

    &:focus {
      outline: 0 none;
      transition: box-shadow 0.2s;
      box-shadow: 0 0 0 0.2rem $focusBorderColor;
    }
  }

  .layout-menu {
    overflow-y: auto;
    flex-grow: 1;
    border-right: 1px solid var(--surface-d);

    .menu-category {
      display: block;
      color: var(--text-color-secondary);
      font-weight: 600;
      user-select: none;
      padding: 1.5rem 0 1rem 0;
      font-size: 0.857rem;
      text-transform: uppercase;
      border-top: 1px solid var(--surface-d);

      &:first-child {
        border-top: 0 none;
        padding-top: 1rem;
      }
    }

    .menu-items {
      padding: 0 0 1rem 0;
      display: flex;
      flex-direction: column;

      a {
        color: white !important;
        display: block;
        padding: 1rem;
        border-radius: 3px;
        cursor: pointer;
        background-color: transparent !important;

        &:hover {
          background-color: #22C36D !important;
        }

        &:focus {
          z-index: 1 !important;
          outline: 0 none !important;
          transition: box-shadow 0.2s;
          font-weight: 700 !important;
          background: #22C36D !important;
        }
      }
    }
  }

  .layout-sidebar-filter {
    padding: 1.25rem 1rem;
    border-bottom: 1px solid var(--surface-d);
    background-color: var(--surface-a);
  }
}

.p-menuitem-link-active, .p-menuitem-active {
  background-color: #22C36D !important;
}

.p-panelmenu .p-panelmenu-header > a {
  border: none !important;

  &:focus {
    box-shadow: none !important;
  }
}
