@import "assets/themes/app/app.scss";

.badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-green {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-red {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-yellow {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-grey {
    background: #cecece;
    color: #282828;
  }
}
